import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useDictModel } from "@/views/base/dict/dictModel";
import { formatNum } from "@/utils/utils";
import { settleTypeDs } from "@/types/FmsDs";
import { IBCMSaleDcDetail } from "../allotType";
import { useSaleDcModel } from "../allotModel";
import useNavModel from "@/model/navModel";
import { useSaleDcDetailsModel } from "./details/saleAllotDetailsModel";
import { globalPrompt } from "@/components/message";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const { getTreeNodeName } = useDictModel(() => []);
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys, setHistoryVisible, setHistoryRow
    } = useSaleDcModel();
    const { resetEditCatch } = useSaleDcDetailsModel();

    const { addNav, findNav, selectNav, switchNavState, selectedNavId, replaceNav } = useNavModel(() => []);

    const onHistoryModel = (record: IBCMSaleDcDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }



    useEffect(() => {
        loadData()
    }, [])
    const onEdit = (code: string) => {
        let SaleDcEditKey = "5-4-2";
        let isFindNav = findNav(SaleDcEditKey);
        if (!isFindNav) {
            addNav(SaleDcEditKey, { id: code as string, mode: "edit" });
            resetEditCatch()
        } else {
            globalPrompt("modal", {
                title: "调出单跳转详情",
                type: "warning",
                text: "您还有调出单单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(SaleDcEditKey, { id: code as string, mode: "edit" });
                    resetEditCatch()
                }
            })
        }
    }
    const columns: ColumnsType<IBCMSaleDcDetail> = [
        {
            title: '序号',
            width: 40,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '调拨单号',
            width: 200,
            dataIndex: 'code',
            fixed: "left",
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => {
                let supplierEditKey = "5-4-2";
                replaceNav(
                    selectedNavId,
                    supplierEditKey,
                    { id: record?.code as string, mode: "edit", },
                    true
                );
                onHistoryModel(record)
            }} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => { e.stopPropagation(); onEdit(text) }}>{text}</p></Tooltip>,

        }, {
            title: '调出单号',
            width: 200,
            dataIndex: 'transferOutCode',
            fixed: "left",
            render: (text: any) => <YhTootip text={text} />,
            // }, {
            //     title: '调入单号',
            //     width: 180,
            //     dataIndex: 'transferInCode',
            //     fixed: "left",
        }, {
            title: '调拨类型',
            width: 160,
            dataIndex: 'transferTypeName',
        }, {
            title: '销售方',
            width: 160,
            dataIndex: 'salesPartyName',
            render: (text: any, record: any, index: number) => <YhTootip text={text} />,
        }, {
            title: '调入门店',
            width: 200,
            dataIndex: 'shopName',
            render: (text: any) => <YhTootip text={text} />,
            //render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '调出门店',
            width: 160,
            dataIndex: 'outShopName',
            render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            render: text => getTreeNodeName(text)
            // }, {
            //     title: '支付状态',
            //     width: 100,
            //     dataIndex: 'payStatus',
            //     render: text => getTreeNodeName(text)
        }, {
            title: '调拨数量',
            width: 100,
            dataIndex: 'totalNum',
        }, {
            title: '成本金额',
            width: 100,
            dataIndex: 'costAmount',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '毛利',
            width: 100,
            dataIndex: 'grossProfit',
            render: text => `￥${text}`
        }, {
            title: '应收金额',
            width: 100,
            dataIndex: 'totalAmount',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '优惠总金额',
            width: 100,
            dataIndex: 'discountTotalAmount',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '已收金额',
            width: 136,
            dataIndex: 'aggregateAmount',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '调入实体',
            width: 160,
            dataIndex: 'bentityName',
            render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '调出实体',
            width: 160,
            dataIndex: 'outBentityName',
            render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '制单员',
            width: 100,
            dataIndex: 'applier',
        }, {
            title: '调出员',
            width: 100,
            dataIndex: 'transferOut',
        }, {
            title: '调入员',
            width: 100,
            dataIndex: 'transferIn',
        }, {
            title: '制单日期',
            width: 180,
            dataIndex: 'applyTime',
        }, {
            title: '调出日期',
            width: 180,
            dataIndex: 'transferOutTime',
        }, {
            title: '调入日期',
            width: 180,
            dataIndex: 'transferInTime',
        }, {
            title: '备注',
            width: 180,
            dataIndex: 'note',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([id]);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                // //全部选中时  (存string[])
                // onSelectAll: (selected, selectedRows, changeRows) => {
                //     let changeRowId = changeRows.map(item => item.id);
                //     selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                //         : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                // },
                // onSelect: (record) => onRow(record.id),
                // selectedRowKeys: selectedRowKeys,
                type: "radio",
                onChange(selectedRowKeys: any) {
                    setSelectedRowKeys(selectedRowKeys)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: (total: number) => `共${total}条`,
                pageSizeOptions: [10, 20, 50, 100, 300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
            })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}
