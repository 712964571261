import React, { FC, useState } from "react";
import { Button, Input, message, Table, Tooltip, Upload } from "antd";
import { RouterParamType } from "@/model/navModel";
import { YhBox } from "@/components/YhBox";
import { useSaleDetailModel } from "./saleDetailModel"
import { ISaleDetailsList } from "../../../../busBusinessType";
import { formatIntPoint, formatNum, formatIntPoint1, bigNumber } from "@/utils/utils";
import { DetailType } from "@/types/ScmEnums";
import { ColumnsType } from "antd/es/table";
import { components } from "@/components/YHEditTable/editTable";
import { importPropsConfig, importPropsConfigScm, messageConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";
import { globalPrompt } from "@/components/message";
import { ITopType } from "@/types/ScmTypes";
import { useStockPoDetailModel } from "../../../../detailedInventory/InventoryDetailsModel"
type ISCMSDcGoodsSearch = {
    data: any[],
    isSearch: boolean
}
export const MaterialTableBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        setMaterialModel,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        detailMaterialLookList,
        // detailMaterialDetailList,
        downloadTpl,
        modelMaterialScreenMethod,
        setTopButsControl,
        //setHistoryVisible,
        setHistoryRow
    } = useSaleDetailModel();
    const { setHistoryVisible } = useStockPoDetailModel()
    const onHistoryModel = (record: ISaleDetailsList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMSDcGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据
    const { shopAndOrg: { shopId } } = useGlobalModel()
    const importCallback = async (list: any) => {
        if (!list?.length) return message.warning('请导入正确格式的配件');
        const newDetailList = mode === "add" ? detailMaterialAddList : detailMaterialEditList
        // const { repetition, newDetailData } = await modelMaterialScreenMethod(list, newDetailList)
        let newShelfRows: any[] = [];
        let str: string = "";
        list.forEach((item: any) => {
            let num: number = item.num ?? 0;
            let price: number = item.price
            if (!item.success) {
                str += `【${item.msg}】`
            }
            if (item.bmsPresaleorderItems && item.bmsPresaleorderItems.length) {
                console.log(item.bmsPresaleorderItems);
                debugger
                for (let i = 0; i < item.bmsPresaleorderItems.length; i++) {
                    let goods = item.bmsPresaleorderItems[i];
                    // 公共字段
                    let publicData = {
                        id: "",
                        detailsId: goods.id,
                        presaleId: goods.id,
                        stockId: goods.id,
                        materialCode: goods.materialCode,
                        materialName: goods.materialName,  //商品名称
                        price: price,
                        inventoryNum: goods.inventoryNum - goods.occupyNum,
                        warehouseCode: goods.warehouseCode,  //仓库编码
                        warehouseName: goods.warehouseName,  //仓库编码
                        shelfCode: goods.shelfCode,
                        replaceCode: goods.replaceCode,
                        unitName: goods.unitName,
                        unitCode: goods.unitCode,
                        costPrice: goods.latestPurPrice,
                        discountRate: 100
                    }
                    // 可用库存 = 库存数量 - 占用库存
                    let kynum = goods.inventoryNum - goods.occupyNum;
                    //当匹配到最后一个配件, 可用库存不满足用户填写数量, 那就设置该配件为异常配件, 数量设置为减完前面配件可用库存剩余的数量
                    if (i === item.bmsPresaleorderItems.length - 1 && kynum < num) {
                        newShelfRows.push({
                            ...publicData,
                            isHave: true,
                            error: item.msg,
                            num, //开单数量
                            costAmount: +bigNumber.toFixed(bigNumber.times(Number(publicData.costPrice ?? 0), Number(num ?? 1)), 2),
                            partsCost: +bigNumber.toFixed(bigNumber.times(Number(price ?? 0), Number(num ?? 1)), 2),
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            //amount: +formatNum(Number(price ?? 0) * (1 - Number(goods.discountRate ?? 0)) * (Number(num ?? 1))),
                            amount: +bigNumber.toFixed(bigNumber.times(Number(price ?? 0), Number(num ?? 1)), 2)
                        })
                        break
                    }
                    if (num <= 0) break;
                    if (kynum) {
                        num = num - kynum;
                        // 当 可用数量 小于
                        let tempNum = num < 0 ? kynum + num : kynum;
                        newShelfRows.push({
                            ...publicData,
                            isHave: item.success,
                            msg: item.msg,
                            num: tempNum, //开单数量
                            costAmount: +bigNumber.toFixed(bigNumber.times(Number(publicData.costPrice ?? 0), Number(tempNum ?? 1)), 2),
                            partsCost: +bigNumber.toFixed(bigNumber.times(Number(price ?? 0), Number(tempNum ?? 1)), 2),
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            //amount: +formatNum(Number(price ?? 0) * (1 - Number(goods.discountRate ?? 0)) * (Number(num ?? 1))),
                            amount: +bigNumber.toFixed(bigNumber.times(Number(price ?? 0), Number(tempNum ?? 1)), 2)
                        })
                    }
                }
            }
        })
        if (mode === "add") {
            setDetailMaterialAddList([...detailMaterialAddList, ...newShelfRows])
        } else if (mode === "edit") {
            setDetailMaterialEditList([...detailMaterialEditList, ...newShelfRows])
        }
        messageConfig(str ? str : "导入成功", 20, "info");
    }
    const switchModelViaible = () => {
        IFormRef.current?.validateFields().then(async res => {
            setMaterialModel(true)
        })
    }

    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource: any | null = null) => {
        // const isEtit = mode !== "add";
        let goodsList = dataSource ? dataSource : typeMaterialList[mode];
        if (searchParms) {
            let reg = new RegExp(searchParms);
            let arr: any = [];
            goodsList.forEach((item: { materialCode: string; materialName: string; }) => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })
            setSearchVal({ data: arr, isSearch: true });
            if (!arr.length) {
                message.warning("列表没有该商品！！")
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }
    //删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailMaterialDetailList = async (mode: string) => {
        if (mode === 'add') {
            const filterDetailList = detailMaterialAddList.filter(
                (el) => !!!detailMaterialAddDataRowKeys.find((ele) => ele === el.detailsId)
            );
            setDetailMaterialAddList(filterDetailList);
            let newSearchData = searchVal.data.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
            globalPrompt('message', { text: `删除成功`, type: 'success' });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (mode === 'edit') {
            const filterDetailList = detailMaterialEditList.filter(
                (el) => !!!detailMaterialEditDataRowKeys.find((ele) => ele === el.detailsId)
            );
            setDetailMaterialEditList(filterDetailList);
            let newSearchData = searchVal.data.filter(
                (el) => !!!detailMaterialEditDataRowKeys.find((ele) => ele === el.detailsId)
            );
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
            globalPrompt('message', { text: `删除成功`, type: 'success' });
            setDetailMaterialEditDataRowKeys([]);
            setDetailMaterialEditDataRow([]);
        }
    };
    const action = <>
        <Input
            size="small"
            allowClear
            datatype="round"
            className="border-r-8 ml-10"
            placeholder="配件编号/配件名称"
            onChange={(e) => { setSearchParms(e.target.value); goodsFilter() }}
            onBlur={(e) => setSearchParms(e.target.value?.trim())}
            onPressEnter={() => goodsFilter()}
            style={{ width: '200px' }}
        />
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 查询</Button>
        {mode !== "look" && <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => switchModelViaible()}
            >新增商品</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => detailMaterialDetailList(mode)}
            >删除商品</Button>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfigScm(`/bcm/transit/sale/saleDetails/import/${shopId}`, importCallback)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => downloadTpl().then()}
            >下载模板</Button>
            {/* <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => setMaterialModel(true)}
            >导入</Button> */}
        </>}
    </>;
    const MaterialColumns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '公交编码',
            width: 120,
            dataIndex: 'materialNo',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span">{text}</p></Tooltip>,
        }, {
            title: '商品编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '可用库存',
            width: 100,
            //dataIndex: 'inventoryNum',
            // dataIndex:'availableNum',
            dataIndex: 'inventoryNum'
        }, {
            title: '仓库',
            width: 150,
            dataIndex: 'warehouseName',
        }, {
            title: '销售数量',
            width: 80,
            dataIndex: 'num',
            editable: mode != "look" ? true : false,
        },
        {
            title: '单价',
            width: 80,
            dataIndex: 'price',
            editable: mode != "look" ? true : false,
        }, {
            title: '零件费',
            width: 120,
            dataIndex: 'partsCost',
        },
        {
            title: '总计',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '成本价',
            width: 100,
            dataIndex: 'costPrice',
        }, {
            title: '成本金额',
            width: 100,
            dataIndex: 'costAmount',
        }, {
            title: '品牌',
            width: 120,
            dataIndex: 'brandName',
        }, {
            title: '单位',
            width: 120,
            dataIndex: 'unitName',
        }, {
            title: '规格',
            width: 120,
            dataIndex: 'model',
        }, {
            title: '预售单号',
            width: 120,
            dataIndex: 'stockCode',
        },
        {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: mode != "look" ? true : false
        }
    ];
    const newSelectMaterialColums = () =>
        MaterialColumns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: ISaleDetailsList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave = (row: ISaleDetailsList) => {
        let { num, price, discountRate, amount, averagePrice, costPrice, inventoryNum } = row
        if (mode == 'edit' && detailMaterialEditList.some((item) => item.detailsId == row.detailsId)) {
            num = +formatIntPoint(num == 1 ? 1 : num, inventoryNum == 0 ? num : inventoryNum, "选择数量>=可用库存数量");  //数量
        } else {
            num = +formatIntPoint(num, inventoryNum, "选择数量>=可用库存数量");  //数量
        }
        // price = +formatIntPoint1(price, costPrice, "修改价格不应小于成本价");;  //价格
        price = +formatIntPoint1(price);  //价格
        discountRate = +formatIntPoint(discountRate, 100, '折扣不能高于100%');  //折扣
        let newRow = {
            ...row,
            num,
            price: +formatNum(price),
            discountRate,
            //partsCost: +formatNum(num * price),
            partsCost: +bigNumber.toFixed(bigNumber.times(num, price), 2),
            //amount: +formatNum(num * price * discountRate / 100),
            amount: +bigNumber.toFixed(bigNumber.times(bigNumber.times(num, price), bigNumber.dividedBy(discountRate, 100)), 2),
            //costAmount: +formatNum(num * costPrice)
            costAmount: +bigNumber.toFixed(bigNumber.times(num, costPrice), 2),

        }

        let newData = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailMaterialEditList([...newData]) : setDetailMaterialAddList([...newData])
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(newData)
        }
        setTopButsControl(true)
    }
    const typeMaterialList = {
        "add": [...detailMaterialAddList],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    //设置维修用料选中的行和数据
    const setTypeMaterialDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeMaterialDetailDataRow = async (selectedRows: ISaleDetailsList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectMaterialColums() as ColumnsType<ISaleDetailsList>}
                // dataSource={typeMaterialList[mode] as ISaleDetailsList[]}
                dataSource={searchVal.isSearch ? searchVal.data : typeMaterialList[mode]}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailMaterialAddDataRowKeys : detailMaterialEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleDetailsList[]) => {
                            setTypeMaterialDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        }
                    }}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }}
            />
        </YhBox>
    )
}