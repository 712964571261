import React from "react";
import { useSaleSoModel } from "../saleSoModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel from "@/model/globalModel";

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setSelectedRow
    } = useSaleSoModel();
    const { getSelectedDictOne } = useDictModel();
    const { beShopDs } = useGlobalModel();
    const searchFields = [
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "销售实体",
        //     fieldName: "bentityCode",
        //     options: beShopDs,
        //     allowClear: true,
        //     showSearch: true,
        // },
        {
            type: SearchTypeEnum.Input,
            placeholder: "销售单号",
            fieldName: "saleCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            options: getSelectedDictOne(DictCodeEnmu.SALE),
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "调拨单号",
            fieldName: "allocationCode",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "销售类型",
            fieldName: "maintenanceTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.SALES_TYPE),
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "发货地区",
            fieldName: "salesTerritoryName"
        },

    ]
    const searchMoreFields = [

        // {
        //     type: SearchTypeEnum.Input,
        //     placeholder: "车辆型号",
        //     fieldName: "vehicleModel"
        // },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "车辆类型",
        //     fieldName: "vehicleTypeCode",
        //     options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
        // },
        // {
        //     type: SearchTypeEnum.Input,
        //     placeholder: "车牌号",
        //     fieldName: "licensePlate"
        // },
        // {
        //     type: SearchTypeEnum.Input,
        //     placeholder: "VIN码",
        //     fieldName: "vin"
        // },

        {
            type: SearchTypeEnum.Select,
            placeholder: "销售方",
            fieldName: "salesPartyCode",
            options: getSelectedDictOne(DictCodeEnmu.SALES_SALESPARTY),
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算方",
            fieldName: "settlementPartyCode",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),

        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)

        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS)
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser"
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["结算开始", "结算结束"],
            rangePickerFieldName: ["statSettleTime", "endSettleTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]
    return (
        <YhPageHeader
            title="销售单"
            size="small"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            // handleSearch={(params) => { loadData(initPgCfg, params) }}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }); setSelectedRow([]) }}
            handleReset={resetSearchParam}
        />
    );
}