import React from "react";
import { Button, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useGoodsModel } from "../materialModel";
import { DataTable } from "./DataTable";
import { useNavModel } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import useGlobalModel from "@/model/globalModel";

export const DataBox = () => {
    const { selectedRowKeys, updateEditFormVal, loadData, exportGoods, downloadTpl } = useGoodsModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const { warehouseList, shopAndOrg: { shopId, shopName }, setLoadingInfo } = useGlobalModel()

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let goodsEditKey = "13-2-2";
        let isFindNav = findNav(goodsEditKey);
        if (!isFindNav) {
            addNav(goodsEditKey, { id: selectedRowKeys[0], mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "物料跳转详情",
                type: "warning",
                text: "您还有物料详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(goodsEditKey, { id: selectedRowKeys[0], mode: "edit" });
                    updateEditFormVal(null);
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "13-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRowKeys[0] as string, mode: "look", },
            true
        );
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_M_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { addNav("13-2-1", { id: "", mode: "add" }) }}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_M_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_M_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_M_IM}>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/material/import`, loadData)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_M_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { exportGoods().then() }}
            >导出</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_M_DLOAD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { downloadTpl().then() }}
            >下载模板</Button>
        </YhAuth>
        <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/material/materialLimitImport`, loadData, undefined, { shopId })}>
            <Button className="mr-5" type="link" shape="round">更新</Button>
        </Upload>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => {
                let file = new File(["First Line Text", "Second Line Text"], "图片");
                importImgGoods({ file: file }).then();
            }}
        >导入图片</Button> */}
    </>;

    return (
        <YhBox title="配件列表" action={action}>
            <DataTable />
        </YhBox>
    )
}

