import React from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {Layout, Avatar, Dropdown, Menu, Select, message, Button} from "antd";
import FontIcon from "../components/YhIcon";
import { useHistory } from "react-router-dom";
import useGlobalModel from "../model/globalModel";
import useNavModel from "../model/navModel";
import Logo from "@/assets/images/header-logo.png"
import { loginServiceAuth2 } from "@/views/login/LoginServiceAuth2";
import { CLIENT_APP_CODE } from "@/utils/constants";
import { closeWebPage } from "@/utils/utils";
import { ISelectOption } from "@/components/YhFormBox/Types";
import { shopService } from "@/views/organization/shop/shopService";
import { isArray } from "util";
import { useToastNotification } from "../hooks/useToastNotification";

const { Header } = Layout;

function VrmHeader() {
    const history = useHistory();
    const { user, shopAndOrg, shopDs, cm, toggleMenu, setUser, setBeShopDs, setShopAndOrg, requestCatchAll, } = useGlobalModel();
    const { wsRef } = useToastNotification({})

    const { initNav ,addNav} = useNavModel();
    //退出登录
    const onLogout = () => {
        if (user.loginType === "account") {
            setUser({ ...user, token: "", shopCode: "" });
            setShopAndOrg({ ...shopAndOrg, shopId: "", shopName: "", shopCode: "" })
            history.push("/login");
            loginServiceAuth2.Logout();
            loginServiceAuth2.recordAppLogoutInfo(CLIENT_APP_CODE, user.userCode ?? "");
            onClearCache()
        } else {
            closeWebPage()
        }
    };
    const onClearCache = () => {
        initNav()
    }
    // 菜单项
    const HeaderMenu = () => {

        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={onClearCache}>关闭所有标签</span>
                </Menu.Item>
                {/* <Menu.Divider /> */}
            </Menu>
        )
    }
    const onShopChang = async (value: string, options: ISelectOption) => {
        if (options && options.value) {
            debugger
            onClearCache();  //清楚页签
            setUser({ ...user, shopCode: options.code,shopId: options.value }) //用户信息门店信息改变
            requestCatchAll(value);  //重新刷新门店id的缓存
            message.success("切换门店成功");

            //切换门店， 设置对应门店下面的实体 缓存
            let { retData } = await shopService.one({
                sourceApp: "vrm",
                branch: "main",
                code: options.code
            });
            let { orgCode, orgName, details } = retData;
            setShopAndOrg({ ...shopAndOrg, shopId: value, shopName: options.label, shopCode: options.code, orgCode, orgName })

            if (isArray(details)) {
                setBeShopDs(details.map(item => ({
                    id: item.id,
                    label: item.bentityName ?? "",
                    value: item.bentityCode ?? ""
                })));
            } else {
                setBeShopDs([]);
            }
        }
    }
    const goExport = () => {
        addNav("15");
    }
    return (
        <Header className="layout-section-header">
            {/* 左侧按钮 */}
            <div className="layout-sider-logo">
                <img className="layout-sider-logo-img" src={Logo} alt="财务管理系统Logo" />
                <span className="one-line">客车管理系统</span>
            </div>
            {/* 右侧内容 */}
            <div className="header-right" style={{ paddingRight: "15px", width: "650px",justifyContent:"flex-end"  }}>
                <Button style={{ marginRight:'10px'}}  onClick={() => {goExport()}}>导出记录</Button>
                <Select
                    defaultValue={shopAndOrg.shopId}
                    style={{ width: "250px" }}
                    className="header-right-item"
                    onChange={(value, options) => onShopChang(value, options as ISelectOption)}
                    options={shopDs}
                />
                {/* 设置 */}
                <Dropdown overlay={HeaderMenu} placement="bottomCenter" trigger={['click']}>
                    <Avatar className="header-right-item config-icon" icon={<FontIcon type="icon-shezhi" />} />
                </Dropdown>

                {/* 登录用户信息 */}
                <span onClick={e => e.preventDefault()} className="header-right-item user-info">
                    <Avatar
                        size="default"
                        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        className="user-icon"
                    />
                    <span className="account">{user.username ? user.username : "未获取到"}</span>
                </span>

                {/* 退出登录 */}
                <FontIcon type="icon-tuichu" className="header-right-item logout-icon" onClick={onLogout} />
            </div>
        </Header>
    )
}

export default VrmHeader;
