import React, { useEffect } from "react";
import { Table, Switch, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useGoodsModel } from "../materialModel";
import { IMaterialDetail } from "../materialService";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys,
        onDisabled, setHistoryRow, setHistoryVisible
    } = useGoodsModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "13-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }


    const onHistoryModel = (record: IMaterialDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }


    const columns: ColumnsType<IMaterialDetail> = [
        {
            title: '序号',
            width: 60,
            dataIndex: 'branch',
            render: (text, record, index) => `${index + 1}`
        }, {
            title: '商品编码',
            width: 200,
            dataIndex: 'code',
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => onHistoryModel(record)} />,
            //render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(record.id)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 200,
            dataIndex: 'name',
            render: (text: any) => <YhTootip text={text} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '替换编码',
            width: 160,
            dataIndex: 'replaceCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
        }, {
            title: '库存下限',
            width: 160,
            dataIndex: 'limitLower',
        }, {
            title: '进货价',
            width: 160,
            dataIndex: 'purchasePrice',
            render: (text: string, record: any) => (record?.materialDetal?.[0]?.purchasePrice) ?? text
        }, {
            title: '零售价',
            width: 120,
            dataIndex: 'retailPrice',
            render: (text: string, record: any) => (record?.materialDetal?.[0].repairPrice) ?? text
        }, {
            title: '创建时间',
            width: 140,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '创建人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '所属门店',
            width: 160,
            dataIndex: 'shopName',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
        }, {
            title: '操作',
            width: 100,
            fixed: "right",
            dataIndex: 'enable',
            render: (text, record) => (
                <Space size="middle">
                    <Switch checked={record.enable === 1} onChange={(checked) => {
                        onDisabled(record.id as string, checked);
                    }} />
                </Space>
            ),
        }
    ];
    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    useEffect(() => {
        loadData()
    }, [])

    return (
        <Table
            rowKey={record => record.id}
            rowSelection={{
                selectedRowKeys: selectedRowKeys, //记录选中
                onChange: (selectedRowKeys: React.Key[], selectedRows: IMaterialDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                }
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions: [10, 20, 50, 100, 300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
