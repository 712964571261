import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSaleOrderModel } from "../saleOrderModel";
import { ISaleOrderDetail } from "../saleOrderTypes"
import YhTag from "@/components/YhTag";
import { useDictModel } from "@/views/base/dict/dictModel";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";

type statusType = {
    "sale-order.create": string,
    "sale-order.transfer": string,
    "sale-order.change": string,
    [key: string]: any
}



export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows, initPgCfg,
        loadData, setSelectedRowKeys, setDescRows, setSearchParam
    } = useSaleOrderModel();
    const { getTreeSelectOne, getSelectedDictOne, getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "5-1-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const statusColor: statusType = {
        "sale-order.create": "blue",
        "sale-order.transfer": "cyan",
        "sale-order.change": "red",
    }
    const columns: ColumnsType<ISaleOrderDetail> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '销售订单号',
            width: 100,
            dataIndex: 'saleOrderCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(text)}>{text}</p></Tooltip>,
        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '销售方',
            width: 160,
            dataIndex: 'salesPartyName',
            render: (text: any, record: any, index: number) => <YhTootip text={text} />,
        }, {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '联系人',
            width: 160,
            dataIndex: 'contact',

        }, {
            title: '订单数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '订单金额',
            width: 80,
            dataIndex: 'totalAmount',
            render: (text) => `￥${text}`,
        }, {
            title: '优惠金额',
            width: 80,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${text}`,
        }, {
            title: '应收金额',
            width: 80,
            dataIndex: 'receivedAmount',
            render: (text) => `￥${text}`,
        }, {
            title: '电话',
            width: 95,
            dataIndex: 'phone',
        }, {
            title: '车辆类型',
            width: 80,
            dataIndex: 'vehicleTypeName',
        }, {
            title: '车牌号',
            width: 80,
            dataIndex: 'licensePlate',
        }, {
            title: 'VIN码',
            width: 120,
            dataIndex: 'vin',
        }, {
            title: '结算方',
            width: 90,
            dataIndex: 'settlementPartyName',
        }, {
            title: '结算类型',
            width: 90,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '发票类型',
            width: 90,
            dataIndex: 'invoiceTypeName',
        }, {
            title: '制单员',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '所属门店',
            width: 120,
            dataIndex: 'shopName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '制单日期',
            width: 140,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '经营实体',
            width: 120,
            dataIndex: 'bentityName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
            fixed: 'right'
        }, {
            title: '备注',
            width: 160,
            fixed: "right",
            dataIndex: 'note',
        }
    ];

    useEffect(() => {
        loadData(initPgCfg, { status: "sale-order.create" })
        setSearchParam({ status: "sale-order.create" });
    }, [])

    //点击行选高亮
    const onRow = (record: ISaleOrderDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record: ISaleOrderDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleOrderDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions: [10, 20, 50, 100, 300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }
            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
