import React, { useEffect, useState, FC } from "react";
import { Button, message, Tooltip } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { } from "@/model/navModel";
import { useSaleRoDetailModel } from "./saleRoDetailModel";
import { globalPrompt } from "@/components/message";
import { ITopType } from "@/types/ScmTypes";
import useGlobalModel, { getFindItem, transOptions, vehicleOption, getVehicleItem } from "@/model/globalModel";
import { IVehicleFields } from "@/views/base/customerFiles/vehicle/vehicleType";
import { vehicleService } from "@/views/base/customerFiles/vehicle/vehicleService";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { DetailType } from "@/types/ScmEnums";
import { debounce } from "lodash";
import { useSaleRoModel } from "../../saleRoModel";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const FormBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const { getSelectedDictOne } = useDictModel();
    const { beShopDs, CustomerData, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel();
    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow
    } = useSaleRoModel();
    const {
        detailEditForm,
        detailAddForm,
        detailLookForm,
        vehicleEditAll,
        vehicleAddAll,
        initForm,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        resetEditCatch,
        insertDetail,
        editDetail,
        setDetailAddForm,
        setDetailAddList,
        setTopButsControl,
        setVehicleAddAll,
        setVehicleEditAll,
        setConsumeHistoryModel,
        setConsumeHistoryVisible,
        formComputeRule,
        detailAddList,
        detailDataSource,
        setDetailDataSource
    } = useSaleRoDetailModel();
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const materialList = mode === "edit" ? detailDataSource : detailAddList
    const setMaterialList = mode === "edit" ? setDetailDataSource : setDetailAddList
    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("5-3-1") && resetAddCatch(mode);
            !findNav("5-3-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])

    const save = () => {
        // //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const resData = await insertDetail(res)
                if (resData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    resetAddCatch(mode);
                    setSelectedRowKeys([resData.id as string])
                    setDescRows([resData])
                    setSelectedRow(resData)
                    goBack();
                }
            } else if (mode === "edit") {
                let { id, returnCode } = detailEditForm
                const resData = await editDetail({ ...res, id, returnCode })
                if (resData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    getDetailEditOne(returnCode as string, mode)
                }
            }
        })
    }
    const goBack = () => {
        replaceNav(selectedNavId, "5-3")
    }
    const onRefresh = async () => {
        if (mode === 'add') {
            IFormRef.current?.resetFields()
            getVehicleAllList()
            setDetailAddForm(initForm)
            setDetailAddList([])

        } else if (mode === 'edit') {
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const rightChildren = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.S_R_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { save() }, 900)}
                >保存</Button>
            </YhAuth>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    //车辆类型
    const vehicleTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ vehicleTypeName: option.label, vehicleTypeCode: option.value });
    }
    //维修类型
    const maintenanceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ maintenanceTypeName: option.label, maintenanceTypeCode: option.value });
    }
    //结算方
    const settlementPartyChange = (value: string, option: ISelectOption) => {
        option && onChange({ settlementPartyName: option.label, settlementPartyCode: option.value });
    }
    //结算方式
    // const paymentMethodChange = (value: string, option: ISelectOption) => {
    //     option && onChange({ paymentMethodName: option.label, paymentMethodCode: option.value });
    // }
    //客户类型
    const customerTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ customerTypeName: option.label, customerTypeCode: option.value });
    }
    //发票类型
    const invoiceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value });
    }
    //销售实体
    const bentityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    useEffect(() => {
        getVehicleAllList()
    }, [mode])

    const getVehicleAllList = async (param?: IVehicleFields) => {
        let params = {
            ...param,
            shopId,
            pageSize: 1000,
            pageNum: 1
        }
        const { retData } = await vehicleService.page(params)
        const { records } = retData
        // if (records.length === 0) { globalPrompt("message", { text: "该客户下没有车辆，点击左侧车辆牌照去新增吧!", type: "warning" }) }
        let newRecords = records.map(item => {
            return {
                ...item,
                label: item.licensePlate,
                value: item.licensePlate
            }
        })
        mode === "add" ? setVehicleAddAll(newRecords) : setVehicleEditAll(newRecords)
    }
    function clearGoodsList() {
        if (materialList.length > 0) globalPrompt('message', { text: '切换成功!需要重新选择商品信息', type: 'warning' })
        setMaterialList([])
    }
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {
            let { contact, phone, address, categoryCode, categoryName } = getFindItem(value, CustomerData)
            option && onChange({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address,
                customerTypeCode: categoryCode,
                customerTypeName: categoryName,
                licensePlate: null,
                vehicleTypeCode: null,
                vehicleTypeName: null,
                vehicleModel: null,
                vin: null
            });
        }
        getVehicleAllList({ customerName: option?.label })
        clearGoodsList()

    }
    //车辆牌照
    const vehicleChange = (value: string, option: ISelectOption) => {
        let VehicleIList = mode === "add" ? vehicleAddAll : vehicleEditAll
        if (value && option) {
            console.log(getVehicleItem(value, VehicleIList))
            let { customerName, customerCode, licensePlate, categoryCode, categoryName, phone, contact, address, vin, typeCode, typeName, model } = getVehicleItem(value, VehicleIList)
            option && onChange({
                customerName,
                customerCode,
                contact,
                phone,
                address,
                vin,
                licensePlate,
                vehicleTypeCode: categoryCode,
                vehicleTypeName: categoryName,
                vehicleModel: model,
                customerTypeCode: typeCode,
                customerTypeName: typeName,
            });
        }
    }
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    const goVehicle = () => {
        replaceNav(
            selectedNavId,
            "13-5-2-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id,
                    customerCode: typeForm[mode].customerCode
                }
            },
            true)
    }
    //销售方
    const salesPartyCodeChange = (value: string, option: ISelectOption) => {
        option && onChange({ salesPartyName: option.label, salesPartyCode: option.value });
    }
    const onConsumption = () => {
        let { customerCode, customerName } = typeForm[mode]
        if (!customerCode) {
            return message.warning("请先选择客户")
        }
        setConsumeHistoryVisible(true)
        setConsumeHistoryModel({ customerName: customerName as string })
    }
    const title = <>
        <span>基础信息</span>
        <Tooltip placement="topLeft" title="消费记录">
            <FontIcon type="icon-xiaofeijilu" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onConsumption}
            />
        </Tooltip>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: title,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "退货单号",
                fieldName: "returnCode",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                options: transOptions(CustomerData),
                onSelectChange: customerChange,
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: "请选择客户名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }]

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "退货原因",
                fieldName: "returnReason",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "address",
            }, {
                //     type: FormItemTypeEnum.Input,
                //     fieldLabel: "去零",
                //     fieldName: "favorableAmount",
                //     rules: [{ type: 'number', transform: (value) => Math.abs(Number(value)), message: `当前不是一个有效数字`, }],
                // }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "客户类型",
                fieldName: "customerTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                onSelectChange: customerTypeChange,
                disable: true,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售实体",
                fieldName: "bentityCode",
                options: beShopDs,
                allowClear: true,
                showSearch: true,
                onSelectChange: bentityChange,
                rules: [{ required: true, message: "请输入销售实体" }]

            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "结算方",
                fieldName: "settlementPartyCode",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
                onSelectChange: settlementPartyChange,
                rules: [{ required: true, message: "请选择结算方" }],

            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售方",
                fieldName: "salesPartyCode",
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SALES_SALESPARTY),
                onSelectChange: salesPartyCodeChange,
                rules: [{ required: true, message: "请选择结算方" }],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售方",
                fieldName: "salesPartyName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "结算类型",
                fieldName: "payType",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                rules: [{ required: true, message: "请选择结算类型" }],
                span: 5,
                labelCol: { offset: 1 },
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            },
            //  {
            //     type: FormItemTypeEnum.Select,
            //     allowClear: true,
            //     showSearch: true,
            //     fieldLabel: '车辆类型',
            //     fieldName: "vehicleTypeCode",
            //     options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
            //     onSelectChange: vehicleTypeChange,
            //     hidden: more,
            // },
            //  {
            //     type: FormItemTypeEnum.Select,
            //     fieldLabel: <span className="click-span" onClick={goVehicle}>车辆牌照</span>,
            //     fieldName: "licensePlate",
            //     allowClear: true,
            //     showSearch: true,
            //     options: mode === "add" ? vehicleOption(vehicleAddAll) : vehicleOption(vehicleEditAll),
            //     onSelectChange: vehicleChange,
            //     hidden: more,
            // },
            //  {
            //     type: FormItemTypeEnum.Input,
            //     fieldLabel: "VIN码",
            //     fieldName: "vin",
            //     hidden: more,
            // },
            //  {
            //     type: FormItemTypeEnum.Input,
            //     fieldLabel: "车辆里程",
            //     fieldName: "mileage",
            //     hidden: more,
            // }, 
            {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "发票类型",
                fieldName: "invoiceTypeCode",
                hidden: more,
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
                onSelectChange: invoiceTypeChange,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工作小时",
                fieldName: "workingHours",
                hidden: more,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
                hidden: more,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "createUser",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库管员",
                fieldName: "storekeeper",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "入库时间",
                fieldName: "stockIoTime",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算员",
                fieldName: "reckoner",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算时间",
                fieldName: "settleTime",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "核销时间",
                fieldName: "payTime",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                hidden: more,
                disable: true,
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆类型Name",
                fieldName: "vehicleTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "维修类型Name",
                fieldName: "maintenanceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算方Name",
                fieldName: "settlementPartyName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算方式Name",
                fieldName: "paymentMethodName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发票类型Name",
                fieldName: "invoiceTypeName",
                hidden: true
            }
        ]
    };



    const onChange = (changedValues: { [key: string]: any }) => {
        mode === "add" ? updateAddFormVal(changedValues) : updatEditFormVal(changedValues)
        setTopButsControl(true)

    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === DetailType.look || (detailEditForm.status === "sale-return-status.settle" && mode === DetailType.edit),
        formValues: typeForm[mode],
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}