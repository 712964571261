import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSaleRoModel } from "../saleRoModel";
import { ISaleRoDetail } from "../saleRoTypes";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";

type statusType = {
    "sale-return-status.write-off": string,
    "sale-return-status.change": string,
    "sale-return-status.settle": string,
    "sale-return-status.stock-io": string,
    "sale-return-status.create": string,
    [key: string]: any
}
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows, setHistoryVisible, setHistoryRow
    } = useSaleRoModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onHistoryModel = (record: ISaleRoDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const onLook = (code: string) => {
        let supplierEditKey = "5-3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }

    const statusColor: statusType = {
        "sale-return-status.write-off": "green",
        "sale-return-status.change": "red",
        "sale-return-status.settle": "cyan",
        "sale-return-status.stock-io": "green",
        "sale-return-status.create": "blue",
    }
    const columns: ColumnsType<ISaleRoDetail> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '退货单号',
            width: 200,
            dataIndex: 'returnCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => {
                let supplierEditKey = "5-3-3";
                replaceNav(
                    selectedNavId,
                    supplierEditKey,
                    { id: record?.returnCode as string, mode: "look", },
                    true
                );
                onHistoryModel(record)
            }} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => { e.stopPropagation(); onLook(text) }}>{text}</p></Tooltip>,
        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '支付状态',
            width: 70,
            dataIndex: 'payStatus',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '销售方',
            width: 160,
            dataIndex: 'salesPartyName',
            render: (text: any, record: any, index: number) => <YhTootip text={text} />,
        }, {
            title: '客户名称',
            width: 200,
            dataIndex: 'customerName',
            render: (text: any) => <YhTootip text={text} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '联系人',
            width: 150,
            dataIndex: 'contact',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '电话',
            width: 95,
            dataIndex: 'phone',
        }, {
            title: '退货数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '优惠金额',
            width: 80,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${text ?? 0}`,
        }, {
            title: '退货金额',
            width: 80,
            dataIndex: 'totalAmount',
            render: (text) => `￥${text ?? 0}`,
        }, {
            title: '应付金额',
            width: 80,
            dataIndex: 'receivedAmount',
            render: (text) => `￥${text ?? 0}`,
        }, {
            title: '已付金额',
            width: 80,
            dataIndex: 'receivableAmount',
            render: (text) => `￥${text ?? 0}`,
        }, {
            title: '退货成本',
            width: 80,
            dataIndex: 'totalCost',
            render: (text) => `￥${text}`,
        },
        // {
        //     title: '销售类型',
        //     width: 80,
        //     dataIndex: 'maintenanceTypeName',
        // },
        {
            title: '结算方',
            width: 160,
            dataIndex: 'settlementPartyName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '结算方式',
            width: 160,
            dataIndex: 'payMethodName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '发票类型',
            width: 80,
            dataIndex: 'invoiceTypeName',
        }, {
            title: '开票状态',
            width: 80,
            dataIndex: 'invoiceStatus',
            render: (text) => text == 1 ? "已开票" : "未开票"
        }, {
            title: '制单员',
            width: 60,
            dataIndex: 'createUser',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '制单日期',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '库管',
            width: 80,
            dataIndex: 'storekeeper',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '所属门店',
            width: 120,
            dataIndex: 'shopName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '退货日期',
            width: 130,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结算日期',
            width: 130,
            dataIndex: 'settleTime',
            render: (text) => momentFormat(text)
        }, {
            title: '作废原因',
            width: 120,
            dataIndex: 'cancelReason',
        }, {
            title: '经营实体',
            width: 120,
            dataIndex: 'bentityName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
            fixed: 'right'
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            fixed: "right",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
    ];

    useEffect(() => {
        loadData().then();
    }, [])

    //点击行选高亮
    const onRow = (record: ISaleRoDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record: ISaleRoDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleRoDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions: [10, 20, 50, 100, 300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }
            })}
            scroll={{ x: 'max-content', y: 520 }}

        />
    )
}
