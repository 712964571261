import React, { useEffect, FC, useState } from "react";
import { Table, Button, Input, Tooltip, Checkbox } from "antd";
import { YHModal } from "@/components/YHModal";
import { useSaleSoDetailModel, IMaterialDetailModel } from "./saleSoDetailModel";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { RouterParamType } from "@/model/navModel";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { YhBut } from "@/components/YhButton";
import { formatNum, formatIntPoint, formatIntPoint1, bigNumber } from "@/utils/utils";
import { stockStatisticService } from "@/views/stock/statistic/stockStatisticService";
import useGlobalModel from "@/model/globalModel";
import { useLockFn } from "ahooks";
import { debounce } from "lodash";
import { IPriceTypeEnum, ISalesTypeEnum } from '@/types/ScmEnums';
import { purchaseDetailsService } from '@/views/stock/purchaseDetails/purchaseDetailsService';
import YhTootip from "@/components/YhTootip";

enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}
export const AddGoodsModal: FC<RouterParamType> = ({ id, mode, parentInfo }) => {
    const { addModalVisible: visible,
        workDataSource,
        workPgCfg,
        addDataSource,
        searchWorkData,
        initPgCfg,
        detailAddList,
        detailDataSource,
        detailAddForm,
        detailEditForm,
        setWorkDataSource,
        resetWorkloadData,
        setSearchWorkData,
        setAddDataSource,
        transformsToPoDetailList,
        loading, setLoading,
        workloadData,
        setAddModalVisible, } = useSaleSoDetailModel();
    const { shopAndOrg } = useGlobalModel();
    const { shopId } = shopAndOrg
    const goodList = mode === 'add' ? detailAddList : detailDataSource
    useEffect(() => {
        if (visible && mode === "add") {
            workloadData(initPgCfg, {}, "add")
        } else if (visible && mode === "edit") {
            workloadData(initPgCfg, {}, "edit")
        }
    }, [visible])

    const [checked, setChecked] = useState(true);

    const column = (optype: number) => [
        {
            title: '序号',
            width: 50,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '商品编号',
            width: 110,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any) => <div style={{ display: "flex", flexWrap: 'nowrap' }}> <YhTootip text={text} /><span style={{ color: 'red' }}>{record.ytNote}</span></div>,
        }, {
            title: '商品名称',
            width: 110,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '可用库存',
            width: 110,
            dataIndex: 'availableStock',
        }, {
            title: '采购单号',
            width: 150,
            dataIndex: 'purchaseCode',
        }, {
            title: '进货价（最近）',
            width: 110,
            dataIndex: 'latestPurPrice',
        }, {
            title: '数量',
            width: 110,
            dataIndex: 'num',
            editable: true,
        }, {
            title: '价格',
            width: 110,
            dataIndex: 'lateSalePrice',
            editable: true,
        }, {
            title: '采购价格',
            width: 110,
            dataIndex: 'purchasePrice',
        }, {
            title: '总计',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '库房',
            width: 120,
            dataIndex: 'warehouseName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '积压标记',
            width: 120,
            dataIndex: 'overstock',
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        },
        {
            title: '替换编号',
            width: 70,
            dataIndex: 'replaceCode',
        },
        {
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            // className: "td-wrap",
            render: (text: string, record: IMaterialDetailModel) => optype === OPT_TYPE.ADD ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={debounce(() => { add(record) }, 100)}
                    disabled={
                        !!addDataSource.find(item => item.id === record.id) ||
                        !!goodList.find(item => item.purchaseId === record.id) ||
                        (record.availableStock == 0 && !!!parentInfo)
                    }
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record)}
                />
            )
        }
    ];
    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IMaterialDetailModel, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType === OPT_TYPE.ADD ? notHandleSave : handleSave,
                    enterRow: optType === OPT_TYPE.ADD ? enterRow : null,
                }),
            }
        })
    const notHandleSave = (row: IMaterialDetailModel) => {
        let { num, lateSalePrice, availableStock, purchasePrice } = row
        num = parentInfo ? +formatIntPoint(num) : +formatIntPoint(num, availableStock, "销售数量>可用库存数量")
        // lateSalePrice = +formatNum(lateSalePrice)
        lateSalePrice = +formatIntPoint1(lateSalePrice, purchasePrice, `修改价格不应小于进货价${purchasePrice}`);
        let newRow = {
            ...row,
            num,
            lateSalePrice: +formatNum(lateSalePrice),
            // amount: +formatNum(num * lateSalePrice)
            amount: +bigNumber.toFixed(bigNumber.times(num, lateSalePrice), 2)
        }
        const index = workDataSource.findIndex((item) => row.id === item.id);
        const item = workDataSource[index];
        workDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkDataSource([...workDataSource])
    }
    const handleSave = (row: IMaterialDetailModel) => {
        let { num, lateSalePrice, availableStock, purchasePrice } = row
        num = parentInfo ? +formatIntPoint(num) : +formatIntPoint(num, availableStock, "销售数量>可用库存数量")
        // lateSalePrice = +formatNum(lateSalePrice)
        lateSalePrice = +formatIntPoint1(lateSalePrice, purchasePrice, `修改价格不应小于进货价${purchasePrice}`);
        let newRow = {
            ...row,
            num,
            lateSalePrice: +formatNum(lateSalePrice),
            // amount: +formatNum(num * lateSalePrice)
            amount: +bigNumber.toFixed(bigNumber.times(num, lateSalePrice), 2)
        }
        const index = addDataSource.findIndex((item) => row.id === item.id);
        const item = addDataSource[index];
        addDataSource.splice(index, 1, { ...item, ...newRow });
        setAddDataSource([...addDataSource])
    }

    // latestPurPrice：最近进货价
    // lateSalePrice：最近销售价
    // purchasePrice：配件档案进货价
    // retailPrice：配件档案销售价
    const add = async (record: IMaterialDetailModel) => {
        let { latestPurPrice, materialCode, lateSalePrice, purchasePrice, retailPrice, petroleumPrice, num, ...params } = record as { latestPurPrice: number, lateSalePrice: number, purchasePrice: number, retailPrice: number, num: number, materialCode: string, petroleumPrice: number }
        let newPrice: number = 0;
        let { priceTypeCode, salesTypeCode, customerCode } = mode === "add" ? detailAddForm : detailEditForm;
        if (salesTypeCode !== ISalesTypeEnum.NORMAL_SALES) {
            newPrice = latestPurPrice ?? purchasePrice
        } else if (salesTypeCode === ISalesTypeEnum.NORMAL_SALES) {
            if (priceTypeCode === IPriceTypeEnum.CUSTOMER_LATEST) {//需要通过接口查询，客户最近一次的销售价
                let { retData: { price } } = await purchaseDetailsService.recentSalesPrice(shopId, materialCode, customerCode as string);
                newPrice = price as any
            } else if (priceTypeCode === IPriceTypeEnum.RECENT) {//售价选择 最近销价
                newPrice = lateSalePrice ?? retailPrice
            } else if (priceTypeCode === IPriceTypeEnum.RETAIL) {//售价选择 零售价
                newPrice = retailPrice
            } else if (priceTypeCode === IPriceTypeEnum.BUYING_PRICE) {//售价选择 进货价
                newPrice = purchasePrice
            }
        }
        let newRecord = {
            ...params,
            latestPurPrice,
            purchasePrice,
            retailPrice,
            materialCode,
            num,
            lateSalePrice: newPrice == 0 ? latestPurPrice : newPrice,
            //amount: +formatNum(num * newPrice)
            amount: +bigNumber.toFixed(bigNumber.times(num, newPrice == 0 ? latestPurPrice : newPrice), 2)
        }
        setAddDataSource(origin => [...origin, record])
    }
    const allAdd = async (record: any) => {
        let newRecord: any = []
        record.forEach(async (item: any) => {
            let { latestPurPrice, materialCode, lateSalePrice, purchasePrice, retailPrice, petroleumPrice, num, ...params } = item as { latestPurPrice: number, lateSalePrice: number, purchasePrice: number, retailPrice: number, num: number, materialCode: string, petroleumPrice: number }
            let newPrice: number = 0;
            let { priceTypeCode, salesTypeCode, customerCode } = mode === "add" ? detailAddForm : detailEditForm;
            if (salesTypeCode !== ISalesTypeEnum.NORMAL_SALES) {
                newPrice = latestPurPrice ?? purchasePrice
            } else if (salesTypeCode === ISalesTypeEnum.NORMAL_SALES) {
                if (priceTypeCode === IPriceTypeEnum.CUSTOMER_LATEST) {//需要通过接口查询，客户最近一次的销售价
                    let { retData: { price } } = await purchaseDetailsService.recentSalesPrice(shopId, materialCode, customerCode as string);
                    newPrice = price as any
                } else if (priceTypeCode === IPriceTypeEnum.RECENT) {//售价选择 最近销价
                    newPrice = lateSalePrice ?? retailPrice
                } else if (priceTypeCode === IPriceTypeEnum.RETAIL) {//售价选择 零售价
                    newPrice = retailPrice
                } else if (priceTypeCode === IPriceTypeEnum.BUYING_PRICE) {//售价选择 进货价
                    newPrice = purchasePrice
                }
            }
            newRecord.push({
                ...params,
                latestPurPrice,
                purchasePrice,
                retailPrice,
                materialCode,
                num,
                lateSalePrice: newPrice == 0 ? latestPurPrice : newPrice,
                //amount: +formatNum(num * newPrice)
                amount: +bigNumber.toFixed(bigNumber.times(num, newPrice == 0 ? latestPurPrice : newPrice), 2)
            })
        })
        setAddDataSource(origin => [...origin, ...newRecord])
    }
    const remove = async (record: IMaterialDetailModel) => {
        const filterAddDataSource = addDataSource.filter(item => item.id !== record.id)
        setAddDataSource(filterAddDataSource)
    }
    const enterRow = async (record: IMaterialDetailModel) => {
        if (record.availableStock == 0) {
            return false
        }
        let findArr = addDataSource.find(item => item.id === record.id);
        if (findArr) {
            let filterArr = addDataSource.filter(item => item.id !== findArr?.id);
            setAddDataSource(filterArr)
        } else {
            setAddDataSource(origin => [...origin, record]);
        }
    }
    const onOK = async () => {
        console.log()
        transformsToPoDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setAddModalVisible(false);
        setAddDataSource([]);
        resetWorkloadData()
        setChecked(true)
    };
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            placeholder="采购单号"
            style={{ width: "150px" }}
            value={searchWorkData?.purchaseCode}
            onChange={(e) => setSearchWorkData({ ...searchWorkData, purchaseCode: e.target.value })}
            onPressEnter={() => workloadData(initPgCfg, { ...searchWorkData }, mode)}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialCode: e.target.value }) }}
            onPressEnter={() => workloadData(initPgCfg, { ...searchWorkData }, mode)}
            placeholder="商品编码"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialName: e.target.value }) }}
            onPressEnter={() => workloadData(initPgCfg, { ...searchWorkData }, mode)}
            placeholder="商品名称"
        />
        {/* <Checkbox checked={checked} onChange={(e) => { setChecked(e.target.checked); workloadData(initPgCfg, { ...searchWorkData, age: e.target.checked ? 1 : 0 }); setSearchWorkData({ ...searchWorkData, age: e.target.checked ? 1 : 0 }) }}>
            是否有库存
        </Checkbox> */}
        <Button onClick={() => workloadData(initPgCfg, { ...searchWorkData }, mode)} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => { resetWorkloadData(); setChecked(false) }} shape="round">重置</Button>
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }} onClick={() => allAdd(workDataSource.filter((record) => !(!!addDataSource.find(item => item.id === record.id) ||
            !!goodList.find(item => item.purchaseId === record.id) ||
            (record.availableStock == 0 && !!!parentInfo))))}>全部添加</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOK}
        onCancel={onCancel}
        title="销售单-选择配件"
        width={1000}
        bodyHeight={710}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                loading={loading}
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<IMaterialDetailModel>}
                dataSource={workDataSource}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={{
                    ...workPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workloadData({ pageNum, pageSize });
                    },
                    showSizeChanger: true
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<IMaterialDetailModel>}
                dataSource={[...addDataSource]}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}