import React from "react";
import { useSaleDcModel } from "../allotModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const { getSelectedDictOne } = useDictModel(() => []);
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSaleDcModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "调拨单号",
            fieldName: "code"
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "调出单号",
            fieldName: "transferOutCode"
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "调入单号",
            fieldName: "transferInCode"
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "销售方",
            fieldName: "salesPartyCode",
            options: getSelectedDictOne(DictCodeEnmu.SALES_SALESPARTY),
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "调入门店",
            fieldName: "shopName"
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            fieldName: "status",
            mode: "multiple",
            options: getSelectedDictOne(DictCodeEnmu.TRANSFER_STATUS)
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "备注",
            fieldName: "note"
        }, {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["outStartTime", "outEndTime"],
            placeholder: ["调出起始日期", "调出结束日期"],
            format: "YYYY-MM-DD",
            alloc: true,
            span: 16,
            xxl: 8,
        },
    ]

    return (
        <YhPageHeader
            title="调出单"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}