import { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { purchaseDetailsService, ISCMPurchaseDetailsSearchFields, ISCMPurchaseDetailsDetail, ISCMPurchaseDetailsTotal, ISCMPoShift, ISCMPurchaseDetailsGoods, ISCMPoShiftImportDetail, ISCMPoSingleShift } from "./purchaseDetailsService";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { useLockFn } from "ahooks";
import { globalPrompt } from "@/components/message";
import { stockStatisticService, IRecordsFields, IRecordsDetail, IStockStatisticDetail } from "../statistic/stockStatisticService";
import { transPgToPagination } from "@/utils/utils";
import { DictCodeEnmu } from '@/types/VrmSaDictEnums';
import { saleOrderService } from '@/views/sale/order/saleOrderService';
import { IPurchaseStock } from '@/views/sale/order/saleOrderTypes';
import { message } from 'antd';

export const useStockPoDetailModel = createModel(function () {
    const { shopAndOrg: { shopId }, setLoadingInfo, user } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<ISCMPurchaseDetailsSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<ISCMPurchaseDetailsDetail[]>([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //列表统计展示数据
    const initStatistics = { stockAmount: 0, stockNum: 0 };
    const [statistics, setStatistics] = useState<ISCMPurchaseDetailsTotal>(initStatistics);
    const [occupyStock, setOccupyStockModel] = useState<IStockStatisticDetail | null>(null)

    const updateSearchParam = (updateParam: ISCMPurchaseDetailsSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //切换门店重置缓存
    useEffect(() => {
        if (user.username) {
            setSearchParam({});
            setSelectedRowKeys([]);
            resetSearchParam()
        }
    }, [shopId]);

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: ISCMPurchaseDetailsSearchFields = searchParam) => {
        let newSelect = {
            occupyNo: searchValue.occupy == 1 ? 0 : undefined,
            occupy: searchValue.occupy == 2 ? 0 : undefined,
            inventoryNum: searchValue.inventoryNum == 1 ? 0 : undefined,
            inventoryNumNo: searchValue.inventoryNum == 2 ? 0 : undefined
        }

        const searchParams = {
            shopId,
            ...searchValue,
            ...newSelect,
            status: `${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }

        try {
            let { retData } = await purchaseDetailsService.stockPage(searchParams);
            const { records, ...pg } = retData;
            setPgCfg(transPgToPagination(pg));
            setDataSource(records);
        } catch (error) {
            message.error(error)
        }
        try {
            let { retData: statistics } = await purchaseDetailsService.amount(searchParams);
            setStatistics(statistics);
        } catch (error) {
            message.error(error)
        }


    }

    //导出
    const exportStockOut = async (searchValue: ISCMPurchaseDetailsSearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                shopId,
                ioFlag: "0",  //出库标记
                ...searchValue,
                id: selectedRowKeys.join(),
                pageSize: -1,
                pageNum: 1,
            }
            let result = await purchaseDetailsService.export(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch {
            setLoadingInfo({ loadingVisible: false, txt: "" })
        }
    }
    /**
     *  //转移货位
     */
    const [shiftShelfModal, setShiftShelfModal] = useState<boolean>(false);
    //批量转移货位
    const [shiftShelfModals, setShiftShelfModals] = useState<boolean>(false);
    //查询条件
    const [searchParamShelf, setSearchParamShelf] = useState<ISCMPurchaseDetailsSearchFields>({});
    //列表数据源
    const [dataSourceShelf, setDataSourceShelf] = useState<IPurchaseStock[]>([]);
    //列表数据源分页参数
    const [pgCfgShelf, setPgCfgShelf] = useState<TablePaginationConfig>(initPgCfg);
    //已选中的
    const [selectedShelfRows, setSelectedShelfRows] = useState<IPurchaseStock[]>([]);
    //移库记录列表
    const [recordsList, setRecordsList] = useState<IRecordsDetail[]>([])
    //移库记录检索
    const [recordsSearch, setRecordsSearch] = useState<IRecordsFields>({})
    //移库记录显示隐藏
    const [recordsVisible, setRecordsVisible] = useState<boolean>(false)

    const loadDataShelf = async (page?: ISearchPage, searchValue: ISCMPurchaseDetailsSearchFields = searchParamShelf) => {
        const searchParams = {
            shopId,
            status: `${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            pageSize: page?.pageSize ?? pgCfgShelf.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: page?.pageNum ?? pgCfgShelf.current ?? DEFAULT_FIRST_PAGE_NUM,
            materialName: searchValue.materialName?.trim() ?? "",
            materialCode: searchValue.materialCode?.trim() ?? "",
        }
        let { retData } = await saleOrderService.stock(searchParams);
        const { records, ...pg } = retData;
        setPgCfgShelf(transPgToPagination(pg));
        setDataSourceShelf(records as any);
    }
    const shiftShelfApi = useLockFn(async () => {
        if (!selectedShelfRows.length) {
            globalPrompt("message", { text: "请选择需要转移的数据", type: "warning" });
            return;
        }
        if (selectedShelfRows.some(item => !item.shelfCode1)) {
            globalPrompt("message", { text: "请选择你要转移的货位", type: "warning" });
            return;
        }
        setLoadingInfo({ loadingVisible: true, txt: "正在转移货位" });
        let arr: ISCMPoSingleShift[] = selectedShelfRows.map(item => ({
            id: item.id as string,
            shelfCode: item.shelfCode1 as string,
            warehouseCode: item.warehouseCode1,
            warehouseName: item.warehouseName1,
        }))
        let { retData } = await purchaseDetailsService.singleShift(arr);
        if (retData) {
            setShiftShelfModal(false);
            setSelectedShelfRows([]);
            loadData();
            setLoadingInfo({ loadingVisible: false, txt: "" });
            globalPrompt("message", { text: "货位转移成功", type: "success" })
        } else {
            globalPrompt("message", { text: "货位转移失败", type: "error" });
        }
    })
    const shiftShelfApis = useLockFn(async () => {
        if (!selectedShelfRows.length) {
            globalPrompt('message', { text: '请导入你要转移的商品', type: 'warning' });
            return;
        }
        setLoadingInfo({ loadingVisible: true, txt: '正在转移货位' });
        let arr: ISCMPoShift[] = selectedShelfRows.map(item => ({
            id: item.id as string,
            warehouseCode: item.warehouseCode1 as string,
            warehouseName: item.warehouseName1 as string,
            shelfId: item.shelfId1 as string,
            shelfCode: item.shelfCode1 as string,
            moveNum: item.moveNum as number,
        }))
        let { retData } = await purchaseDetailsService.shift(arr);
        if (retData) {
            setSelectedShelfRows([]);
            loadData();
            setLoadingInfo({ loadingVisible: false, txt: "" });
            globalPrompt("message", { text: "货位转移成功", type: "success" })
            setShiftShelfModals(false)
        } else {
            globalPrompt("message", { text: "货位转移失败", type: "error" });
        }
    });

    const shelftDownloadTpl = async () => {
        try {
            setLoadingInfo({ loadingVisible: true, txt: '正在下载...' });
            let result = await purchaseDetailsService.downloadTpl();
            convertRes2Blob(result);
            setLoadingInfo({ loadingVisible: false, txt: '' });
        } catch {
            setLoadingInfo({ loadingVisible: false, txt: '' });
        }
    };
    /**
     * 详情
     */
    const [formVal, setFormVal] = useState<object & { code: string }>({ code: "" });
    const [goodsDetail, setGoodsDetail] = useState<ISCMPurchaseDetailsGoods[]>([]);

    //获取一条数据
    const getDetailOne = async (id: string) => {
        // let { retData } = await purchaseDetailsService.one(id);
        // let { details, ...formVal } = retData;
        // if (retData) {
        //     setFormVal(formVal);
        //     setGoodsDetail(details);
        // }
    };
    //移库记录查看
    const records = async () => {
        let params = {
            ...recordsSearch,
            "pageNum": 1,
            "pageSize": 10000,
            shopId: shopId
        }
        let { retData } = await stockStatisticService.records(params);
        let { records } = retData
        setRecordsList(records)
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        statistics,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportStockOut,
        formVal, goodsDetail,
        getDetailOne,
        shiftShelfModal, setShiftShelfModal,
        shiftShelfModals, setShiftShelfModals,
        /***** 转移货位*******/
        searchParamShelf, setSearchParamShelf,
        dataSourceShelf,
        selectedShelfRows, setSelectedShelfRows,
        pgCfgShelf, setPgCfgShelf,
        loadDataShelf, shiftShelfApi,
        shiftShelfApis,
        shelftDownloadTpl,
        recordsList, setRecordsList,
        records,
        recordsVisible, setRecordsVisible,
        recordsSearch, setRecordsSearch,
        occupyStock, setOccupyStockModel,
    }
});
