import React, { useEffect, useState, FC } from "react";
import { Button, message, Input, Tooltip } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel from "@/model/navModel";
import { bigNumber, formatNum } from "@/utils/utils"
import { globalPrompt } from "@/components/message";
import { useSaleOrderDetailModel } from "./saleOrderDetailModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel, { getFindItem, transOptions, vehicleOption, getVehicleItem } from "@/model/globalModel";
import { vehicleService } from "@/views/base/customerFiles/vehicle/vehicleService";
import { IVehicleFields } from "@/views/base/customerFiles/vehicle/vehicleType";
import { ITopType } from "@/types/ScmTypes";
import { DetailType, IPriceTypeEnum, ISalesTypeEnum } from "@/types/ScmEnums";
import { debounce } from "lodash";
import { useSaleOrderModel } from "../../saleOrderModel";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { stockStatisticService } from '@/views/stock/statistic/stockStatisticService';
import { purchaseDetailsService } from '@/views/stock/purchaseDetails/purchaseDetailsService';
export const FormBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow
    } = useSaleOrderModel();
    const { getSelectedDictOne } = useDictModel();
    const { beShopDs, CustomerData, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel();

    const {
        detailEditForm,
        detailAddForm,
        detailLookForm,
        vehicleEditAll,
        vehicleAddAll,
        initForm,
        detailAddList,
        detailDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        resetEditCatch,
        insertDetail,
        editDetail,
        setDetailAddForm,
        setDetailAddList,
        setVehicleAddAll,
        setVehicleEditAll,
        setDetailDataSource,
        setConsumeHistoryModel,
        setConsumeHistoryVisible,
        formComputeRule
    } = useSaleOrderDetailModel();
    const materialList = mode === "edit" ? detailDataSource : detailAddList
    const setMaterialList = mode === "edit" ? setDetailDataSource : setDetailAddList
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    let { maintenanceTypeCode, customerCode, priceTypeCode } = mode === "add" ? detailAddForm : detailEditForm;

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("5-1-1") && resetAddCatch(mode);
            !findNav("5-1-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])

    const save = () => {
        IFormRef.current?.validateFields().then(async res => {
            let goods = mode === 'edit' ? detailDataSource : detailAddList
            let notAmount = goods.filter((item) => Number(item.price) < item.latestPurPrice)
            if (notAmount.length) {
                // message.warning(`【${notAmount.map((item) => item.materialName).join()}】请调整商品合计不可为0元`)
                // return false
                globalPrompt('modal', {
                    title: '销售订单详情保存',
                    type: 'warning',
                    text: `【${notAmount.map((item) => item.materialName).join()}】商品单价少于成本价,是否确认？？`,
                    okText: '确定',
                    onOk: debounce(
                        async () => {
                            if (mode === "add") {
                                const resData = await insertDetail(res)
                                if (resData) {
                                    globalPrompt("message", { text: "新增成功", type: "success" });
                                    resetAddCatch(mode);
                                    setSelectedRowKeys([resData.id as string])
                                    setDescRows([resData])
                                    setSelectedRow(resData)
                                    goBack();
                                }
                            } else if (mode === "edit") {
                                let { id, saleOrderCode } = detailEditForm
                                const resData = await editDetail({ ...res, id, saleOrderCode })
                                if (resData) {
                                    globalPrompt("message", { text: "编辑成功", type: "success" });
                                    getDetailEditOne(saleOrderCode as string, mode)
                                }
                            }
                        },
                        500
                    ),
                    cancelText: '取消',
                    onCancel: () => {
                        return
                    },
                })
            } else {
                if (mode === "add") {
                    const resData = await insertDetail(res)
                    if (resData) {
                        globalPrompt("message", { text: "新增成功", type: "success" });
                        resetAddCatch(mode);
                        setSelectedRowKeys([resData.id as string])
                        setDescRows([resData])
                        setSelectedRow(resData)
                        goBack();
                    }
                } else if (mode === "edit") {
                    let { id, saleOrderCode } = detailEditForm
                    const resData = await editDetail({ ...res, id, saleOrderCode })
                    if (resData) {
                        globalPrompt("message", { text: "编辑成功", type: "success" });
                        getDetailEditOne(saleOrderCode as string, mode)
                    }
                }
            }
        })
    }
    const goBack = () => {
        replaceNav(selectedNavId, "5-1")
    }
    const onRefresh = async () => {
        if (mode === 'add') {
            IFormRef.current?.resetFields()
            getVehicleAllList()
            setDetailAddForm(initForm)
            setDetailAddList([])

        } else if (mode === 'edit') {
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const onDiscount = () => {
        let discountVal: string = "";
        globalPrompt("modal", {
            width: 380,
            title: "折扣",
            type: "info",
            // text: "",
            text: <>
                <span>请输入折扣系数</span>
                <Input
                    onChange={(e) => { discountVal = e.target.value }}
                    suffix="%"
                />
            </>,
            okText: "确定",
            onOk: () => {
                var re = /^[0-9]+.?[0-9]*$/;
                return new Promise((resolve, reject) => {
                    if (!re.test(discountVal)) {
                        reject("输入的值不是数字，请重新输入")
                        message.error("输入的值不是数字，请重新输入")

                    } else {
                        resolve(discountVal)
                    }
                }).then(res => {
                    onSetDetailsTableDiscount(+discountVal)
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onSetDetailsTableDiscount = async (discount: number) => {
        let detailsList = mode === "add" ? detailAddList : detailDataSource
        let newDetailsList = detailsList.map((item) => {
            return {
                ...item,
                discountRate: discount,
                //amount: +formatNum(Number(item.notAmount) * discount) / 100,
                amount: +bigNumber.toFixed(bigNumber.dividedBy(bigNumber.times(item.notAmount as number, discount), 100), 2)
            }
        })
        mode === "add" ? setDetailAddList(newDetailsList) : setDetailDataSource(newDetailsList)
    }
    const rightChildren = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.S_SO_ED_DISC}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onDiscount}
                >折扣</Button>
            </YhAuth>
        }
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.S_SO_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { save() }, 900)}
                >保存</Button>
            </YhAuth>}
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>

    //售价选择
    const priceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ priceTypeName: option.label, priceTypeCode: option.value, markup: value == 'price-selection.buying-price' ? 'Markup-rate.0' : undefined, });
        if (value) {
            alterListPrice(value, maintenanceTypeCode as string, customerCode as string);
        }
    }

    //from销售类型，售价选择时更改 详情表格的价格信息
    const alterListPrice = async (priceTypeCode: string | undefined, maintenanceTypeCode: string, customerCode: string) => {
        let detailsList = mode == "add" ? detailAddList : detailDataSource;
        if (!detailsList.length) {
            return false;
        }
        if (!maintenanceTypeCode || !customerCode) {
            message.warning("请补全客户名称，销售类型才可以，对下列表格的价格数据进行更改");
            return false;
        }
        let newDetailsList: any[] = []
        await Promise.all(detailsList.map(async item => {
            let { stockId, markup, latestPurPrice, materialCode, lateSalePrice, discountRate, purchasePrice, retailPrice, amount, num, ...params } = item as { latestPurPrice: number, markup: string, lateSalePrice: number, purchasePrice: number, retailPrice: number, num: number, materialCode: string, discountRate: number, stockId: string, amount: number, }
            let newPrice: number = 0;
            if (maintenanceTypeCode !== ISalesTypeEnum.NORMAL_SALES) {
                newPrice = await findPoDetailPrice(stockId, ["latestPurPrice", "purchasePrice"], true)// latestPurPrice ?? purchasePrice
            } else if (maintenanceTypeCode === ISalesTypeEnum.NORMAL_SALES) { // 销售类型为正常销售
                if (priceTypeCode === IPriceTypeEnum.CUSTOMER_LATEST) { //需要通过接口查询，客户最近一次的销售价
                    await purchaseDetailsService.recentSalesPrice(shopId, materialCode, customerCode as string).then(res => {
                        let { retData: { price } } = res;
                        newPrice = price as any
                    });
                } else if (priceTypeCode === IPriceTypeEnum.RECENT) {//售价选择 最近销价
                    newPrice = await findPoDetailPrice(stockId, ["lateSalePrice", "retailPrice"], true)
                } else if (priceTypeCode === IPriceTypeEnum.RETAIL) {//售价选择 零售价
                    newPrice = await findPoDetailPrice(stockId, ["retailPrice"])
                } else if (priceTypeCode === IPriceTypeEnum.BUYING_PRICE) {//售价选择 进货价
                    newPrice = await findPoDetailPrice(stockId, ["latestPurPrice", "purchasePrice"]);
                }
            }
            let markUpNum = 1
            if (priceTypeCode === IPriceTypeEnum.BUYING_PRICE) {
                let nums: any = getSelectedDictOne(DictCodeEnmu.MARKUP_RATE).find(item => item.value == markup ? markup : 'Markup-rate.0');
                nums = nums?.label.replace("%", "") / 100;
                markUpNum += nums ?? 0
            }
            return {
                ...params,
                latestPurPrice,
                purchasePrice,
                retailPrice,
                lateSalePrice,
                stockId,
                materialCode,
                num,
                discountRate,
                // price: newPrice,
                // //notAmount: +formatNum(num * newPrice),
                // notAmount:bigNumber.times(num,newPrice),
                // //amount: +formatNum(num * newPrice * (discountRate / 100))
                // amount:+bigNumber.toFixed(bigNumber.minus(bigNumber.minus(num,newPrice),bigNumber.dividedBy(discountRate,100)),2)
                price: +bigNumber.toFixed(bigNumber.times(markUpNum, newPrice), 2),
                notAmount: +bigNumber.toFixed(bigNumber.times(num, bigNumber.times(markUpNum, newPrice)), 2),
                amount: +bigNumber.toFixed(bigNumber.times(bigNumber.times(num, bigNumber.times(markUpNum, newPrice)), bigNumber.dividedBy(discountRate, 100)), 2),
            }
        })).then(reslist => {
            newDetailsList = reslist
        })
        console.log(newDetailsList)
        mode === "add" ? setDetailAddList(newDetailsList) : setDetailDataSource(newDetailsList);
    }

    //根据销售类型，售价选择去查询采购明细对应的单价
    const findPoDetailPrice = async (stockId: string, priceType: string[], priceTypeLen?: boolean) => {
        let resultPrice: number = 0;
        await stockStatisticService.findId(stockId).then(res => {
            let retData = res.retData;
            resultPrice = priceTypeLen ? retData?.[priceType[0]] ?? retData?.[priceType[1]] : retData?.[priceType[0]];

        })
        return resultPrice ?? 0
    }

    //车辆类型
    const vehicleTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ vehicleTypeName: option.label, vehicleTypeCode: option.value });
    }
    //维修类型
    const maintenanceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ maintenanceTypeName: option.label, maintenanceTypeCode: option.value, priceTypeName: undefined, priceTypeCode: undefined, markup: undefined });
        if (value === ISalesTypeEnum.NORMAL_SALES) {
            option && onChange({ maintenanceTypeName: option.label, maintenanceTypeCode: option.value, priceTypeName: "零售价", priceTypeCode: "price-selection.retail" });
        }
        if (!value) {
            onChange({ maintenanceTypeName: undefined, maintenanceTypeCode: undefined, priceTypeName: undefined, priceTypeCode: undefined, markup: undefined });
        }
        if (value) {
            alterListPrice(value === ISalesTypeEnum.NORMAL_SALES ? "price-selection.retail" : undefined, value, customerCode as string);
        }
    }
    //结算方
    const settlementPartyChange = (value: string, option: ISelectOption) => {
        option && onChange({ settlementPartyName: option.label, settlementPartyCode: option.value });
    }
    //结算方式
    const paymentMethodChange = (value: string, option: ISelectOption) => {
        option && onChange({ paymentMethodName: option.label, paymentMethodCode: option.value });
    }
    //客户类型
    const customerTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ customerTypeName: option.label, customerTypeCode: option.value });
    }
    //发票类型
    const invoiceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value });
    }
    //销售实体
    const bentityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    useEffect(() => {
        getVehicleAllList()
    }, [mode])

    const getVehicleAllList = async (param?: IVehicleFields) => {
        let params = {
            ...param,
            shopId,
            pageSize: 1000,
            pageNum: 1
        }
        const { retData } = await vehicleService.page(params)
        const { records } = retData
        let newRecords = records.map(item => {
            return {
                ...item,
                label: item.licensePlate,
                value: item.licensePlate
            }
        })
        mode === "add" ? setVehicleAddAll(newRecords) : setVehicleEditAll(newRecords)
    }
    function clearGoodsList() {
        if (materialList.length > 0) globalPrompt('message', { text: '切换成功!需要重新选择商品信息', type: 'warning' })
        setMaterialList([])
    }
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {
            let { name, code, contact, phone, address, categoryCode, categoryName } = getFindItem(value, CustomerData)
            option && onChange({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address,
                customerTypeCode: categoryCode,
                customerTypeName: categoryName,
                licensePlate: null,
                vehicleTypeCode: null,
                vehicleTypeName: null,
                vin: null
            });
        }
        getVehicleAllList({ customerName: option?.label })
        // clearGoodsList()

    }
    //车辆牌照
    const vehicleChange = (value: string, option: ISelectOption) => {
        let VehicleIList = mode === "add" ? vehicleAddAll : vehicleEditAll
        if (value && option) {
            let { customerName, customerCode, licensePlate, model, brandCode, brandName, categoryCode, categoryName, phone, contact, address, vin, typeCode, typeName } = getVehicleItem(value, VehicleIList)
            option && onChange({
                customerName,
                customerCode,
                contact,
                phone,
                address,
                vin,
                licensePlate,
                vehicleTypeCode: categoryCode,
                vehicleTypeName: categoryName,
                customerTypeCode: typeCode,
                customerTypeName: typeName,
                vehicleModel: model,
            });
        }
    }
    //加价率
    const markupChange = (value: string, option: ISelectOption) => {
        option && onChange({ markup: option.value });
        if (materialList.length > 0) {
            let num: any = option.label.replace("%", "");
            num = num / 100 + 1;
            let changeAmount: any = []
            materialList.map((item: any) => {
                changeAmount.push({
                    ...item,
                    price: +bigNumber.toFixed(bigNumber.times(num, item.latestPurPrice), 2),
                    notAmount: +bigNumber.toFixed(bigNumber.times(item.num, bigNumber.times(num, item.latestPurPrice)), 2),
                    amount: +bigNumber.toFixed(bigNumber.times(bigNumber.times(item.num, bigNumber.times(num, item.latestPurPrice)), bigNumber.dividedBy(item.discountRate, 100)), 2),
                })
            })
            setMaterialList(changeAmount)
        }
    }
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    const goVehicle = () => {
        replaceNav(
            selectedNavId,
            "13-5-2-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id,
                    customerCode: typeForm[mode].customerCode
                }
            },
            true)
    }

    const onConsumption = () => {
        let { customerCode, customerName } = typeForm[mode]
        if (!customerCode) {
            return message.warning("请先选择客户")
        }
        setConsumeHistoryVisible(true)
        setConsumeHistoryModel({ customerName: customerName as string })
    }
    //销售方
    const salesPartyCodeChange = (value: string, option: ISelectOption) => {
        option && onChange({ salesPartyName: option.label, salesPartyCode: option.value });
    }
    const title = <>
        <span>基础信息</span>
        <Tooltip placement="topLeft" title="消费记录">
            <FontIcon type="icon-xiaofeijilu" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onConsumption}
            />
        </Tooltip>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: title,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "订单编号",
                fieldName: "saleOrderCode",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                options: transOptions(CustomerData),
                onSelectChange: customerChange,
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: "请选择客户名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "客户类型",
                fieldName: "customerTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                onSelectChange: customerTypeChange,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售实体",
                fieldName: "bentityCode",
                options: beShopDs,
                allowClear: true,
                showSearch: true,
                onSelectChange: bentityChange,
                rules: [{ required: true, message: "请选择销售实体" }]
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "销售类型",
                fieldName: "maintenanceTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.SALES_TYPE),
                onSelectChange: maintenanceTypeChange,
                rules: [{ required: true, message: "请选择销售类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "售价选择",
                fieldName: "priceTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.PRICE_SELECTION),
                onSelectChange: priceTypeChange,
                disable: maintenanceTypeCode !== ISalesTypeEnum.NORMAL_SALES, // mode !== "add" ? true : addSaleType !== ISalesTypeEnum.NORMAL_SALES,
            },
            ...((str) => {
                if (str) {
                    return [
                        {
                            type: FormItemTypeEnum.Select,
                            allowClear: true,
                            showSearch: true,
                            fieldLabel: "加价率",
                            fieldName: "markup",
                            options: getSelectedDictOne(DictCodeEnmu.MARKUP_RATE),
                            onSelectChange: markupChange,
                            rules: [{ required: true, message: "请选择加价率" }]
                        },
                    ]
                } else {
                    return []
                }
            })(priceTypeCode == 'price-selection.buying-price'),
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售方",
                fieldName: "salesPartyCode",
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SALES_SALESPARTY),
                onSelectChange: salesPartyCodeChange,
                rules: [{ required: true, message: "请选择结算方" }],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售方",
                fieldName: "salesPartyName",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "结算方",
                fieldName: "settlementPartyCode",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
                onSelectChange: settlementPartyChange,
                rules: [{ required: true, message: "请选择结算方" }],
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "结算类型",
                fieldName: "payType",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                span: 5,
                labelCol: { offset: 1 },
                rules: [{ required: true, message: "请选择结算类型" }],
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工作小时",
                fieldName: "workingHours",
                hidden: more,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "address",
                hidden: more,
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "车辆类型",
                fieldName: "vehicleTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
                onSelectChange: vehicleTypeChange,
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goVehicle}>车辆牌照</span>,
                fieldName: "licensePlate",
                allowClear: true,
                showSearch: true,
                options: mode === "add" ? vehicleOption(vehicleAddAll) : vehicleOption(vehicleEditAll),
                onSelectChange: vehicleChange,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "VIN码",
                fieldName: "vin",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆里程",
                fieldName: "mileage",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "发票类型",
                fieldName: "invoiceTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
                onSelectChange: invoiceTypeChange,
                hidden: more,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
                hidden: more,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "createUser",
                disable: true,
                hidden: more,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                disable: true,
                hidden: more,
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆类型Name",
                fieldName: "vehicleTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "维修类型Name",
                fieldName: "maintenanceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算方Name",
                fieldName: "settlementPartyName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算方式Name",
                fieldName: "paymentMethodName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发票类型Name",
                fieldName: "invoiceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "售价选择Name",
                fieldName: "priceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售类型",
                fieldName: "maintenanceTypeName",
                hidden: true
            }
        ]
    };



    const onChange = (changedValues: { [key: string]: any }) => {
        mode === "add" ? updateAddFormVal(changedValues) : updatEditFormVal(changedValues)
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        disabled: mode === DetailType.look,
        labelAlign: "right",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}