import React from "react";
import { usePurchasePlanModel } from "../purchasePlanModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";


export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePurchasePlanModel();
    const { getSelectedDictOne } = useDictModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "采购订单号",
            fieldName: "planCode",
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "供应商",
            fieldName: "supplierName",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "订单类型",
            fieldName: "purchaseTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE),
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "商品类型",
            fieldName: "materialTypeCode",
            // options: [{ label: "正常", value: "0" }, { label: "非正常", value: "9" }]
            options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),

        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            options: getSelectedDictOne(DictCodeEnmu.PURCHASE_PLAN),
        }
    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]
    return (
        <>
            <YhPageHeader
                size="small"
                title="采购订单"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                searchMoreFields={searchMoreFields}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
        </>
    );
}