import { getBaseRequestUrl, getToken } from './constants';
import { message, notification } from 'antd';
import { IconType } from 'antd/lib/notification';

interface FilrResponse {
	retCode: number;
	retData: string;
	retMsg: string;
	success: boolean;
	[key: string]: any;
}
interface File {
	uid?: string;
	name?: string;
	status?: string;
	response: FilrResponse;
	linkProps?: any;
}
interface Upload {
	file: File;
	fileList: any;
	event: any;
}

export function importPropsConfig(url: string, callback: any, loadingFn?: () => void, params = {}, isMessage = true): object {
	let cfg = {
		name: 'file',
		action: getBaseRequestUrl() + url,
		timeout: 300000,
		headers: {
			authorization: getToken(),
			timeout: 300000,
		},
		data: {
			...params
		},
		onChange(info: Upload) {
			loadingFn?.();
			try {
				if (info.file.status === 'done' && info.file?.response?.retCode === 200) {
					isMessage && message.success({ content: `${info.file.name}导入${typeof info?.file?.response?.retData === "string" ? info?.file?.response?.retData : info.file.response.retMsg}`, duration: 8 });
					callback && callback(info?.file?.response?.retData)
				} else if (info.file.status === 'done' && info.file.response.list.length !== 0) {
					isMessage && message.success({ content: `${info.file.name}导入${info.file.response.msg}`, duration: 8 });
					callback && callback(info.file.response.list)
				} else if (info.file.status === 'error') {
					callback?.(undefined);
					message.error(`${info?.file?.response?.retData}：${info.file.name}导入异常，请尝试重新导入`);
				}
			} catch (error) {
				// message.warning("导出异常，请重新尝试");
				callback?.(undefined);
			}
		},
	}
	return cfg
}
export function importPropsConfigs(url: string, callback: any, loadingFn?: () => void, isMessage = true): object {
	let cfg = {
		name: 'file',
		action: getBaseRequestUrl() + url,
		timeout: 300000,
		headers: {
			authorization: getToken(),
			timeout: 300000,
		},
		onChange(info: Upload) {
			loadingFn?.();
			try {
				if (info.file.status === 'done' && info.file?.response?.retCode === 200) {
					isMessage && messageConfig(`${info.file.name}导入${typeof info?.file?.response?.retData === "string" ? info?.file?.response?.retData : info.file.response.retMsg}`, 20, "info");
					// message.info(`${info.file.name}导入${typeof info?.file?.response?.retData === "string" ? info?.file?.response?.retData : info.file.response.retMsg}`, 20);
					callback?.(info?.file?.response?.retData);
				} else if (info.file.status === 'error' && info.file.response && info?.file?.response?.retData) {
					isMessage && messageConfig(`${info?.file?.response?.retData}：${info.file.name}导入异常，请尝试重新导入`, 20, "error");
					// message.error(`${info?.file?.response?.retData}：${info.file.name}导入异常，请尝试重新导入`, 20);
					callback?.(undefined);
				} else if (info.file.status === 'error') {
					message.error("导入错误");
					callback?.(undefined);
				}
				else if (!info.file.status) {
					callback?.(undefined);
				}
			} catch (error) {
				message.warning("导出异常，请重新尝试");
				callback?.(undefined);
			}
		},
	}
	return cfg
}
export function importPropsConfigSms(url: string, callback: any, loadingFn?: () => void,): object {
	let cfg = {
		name: 'file',
		action: getBaseRequestUrl() + url,
		headers: {
			authorization: getToken(),
		},
		onChange(info: Upload) {
			loadingFn?.();
			try {
				// if (info.file.status === 'done' && info.file?.response?.retCode === 200) {
				//     message.success({ content: `${info.file.name}导入${info?.file?.response?.retData}`, duration: 8 });
				//     callback && callback()
				// } else 
				if (info.file.status === 'done' && info?.file?.response?.retData.length !== 0) {
					message.success({ content: `${info.file.name}导入${info.file.response.retMsg}`, duration: 8 });
					callback && callback(info?.file?.response?.retData)
				} else if (info.file.status === 'error') {
					callback?.(undefined);
					message.error(`${info.file.response.retMsg}：${info.file.name}导入异常，请尝试重新导入`);
				}
			} catch (error) {
				// message.warning("导出异常，请重新尝试");
				callback?.(undefined);
			}
		},
	}
	return cfg
}

export const messageConfig = (content: string, duration: number, type: IconType) => {
	notification.open({
		description: "",
		message: content,
		duration: duration,
		type: type,
		style: {
			width: 900,
		},
		placement: "topRight"
	})
}

export function importPropsConfigScm(url: string, callback: any, loadingFn?: () => void, isMessage = true): object {
	let cfg = {
		name: 'file',
		action: getBaseRequestUrl() + url,
		timeout: 300000,
		headers: {
			authorization: getToken(),
			timeout: 300000,
		},
		onChange(info: Upload) {
			loadingFn?.();
			try {
				if (info.file.status === 'done' && info.file?.response?.retCode === 200) {
					isMessage && messageConfig(`${info.file.name}导入${typeof info?.file?.response?.retData === "string" ? info?.file?.response?.retData : info.file.response.retMsg}`, 20, "info");
					// message.info(`${info.file.name}导入${typeof info?.file?.response?.retData === "string" ? info?.file?.response?.retData : info.file.response.retMsg}`, 20);
					callback?.(info?.file?.response?.retData);
				} else if (info.file.status === 'error' && info.file.response && info?.file?.response?.retData) {
					isMessage && messageConfig(`${info?.file?.response?.retData}：${info.file.name}导入异常，请尝试重新导入`, 20, "error");
					// message.error(`${info?.file?.response?.retData}：${info.file.name}导入异常，请尝试重新导入`, 20);
					callback?.(undefined);
				} else if (info.file.status === 'error') {
					message.error("导入错误");
					callback?.(undefined);
				}
				else if (!info.file.status) {
					callback?.(undefined);
				}
			} catch (error) {
				message.warning("导入异常，请重新尝试");
				callback?.(undefined);
				console.log('异常', error)
			}
		},
	}
	return cfg
}